import React from 'react';
import { Prompt, Question } from '../data/Question';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { BaseImage, DisplayElement } from '../data/Models';
import { Drop } from './Drop';
import UserSettings from '../data/UserSettings';
import TimerControl from './TimerControl';

const TimerSettings = {
    TotalTime: 10000,
    IncrementTime: 2000
};

type State = {}
type Props = {
    userSettings: UserSettings,
    question: Question,
    autoPlayDrop: boolean,
    onSelected: (q: Question, prompt: Prompt) => void,
    onTimeout: () => void
}

let GetImageElement = (de: DisplayElement) => {
    if (de.Image === null) {
        return null;
    }

    let image: string | BaseImage = de.Image;
    if (image instanceof BaseImage) {
        return null;
    } else {
        return (
            <CardMedia
            component="img"
            image={(image as string)}
            width="100%"
            height="auto"
            sx={{maxHeight: "50"}}
            />
        )
    }
}

export default class QuestionControl extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
    }

    render() {
        let q = this.props.question;
        let challengeImage = GetImageElement(q.Challenge);
        let challengeText = q.Challenge.Text;
        let drop = q.Challenge.SoundSrc === null 
            ? null 
            : <Drop 
                url={q.Challenge.SoundSrc} 
                volume={this.props.userSettings.Volume} 
                autoPlay={this.props.userSettings.AutoPlay && this.props.autoPlayDrop} />

        let promptToButton = (q: Question, prompt: Prompt): JSX.Element => {
            return (
                <Grid item 
                  key={prompt.Code}>
                    <Button
                        variant="outlined"
                        size='medium'
                        onClick={(e) => this.props.onSelected(q, prompt)}
                        >
                        <Typography variant='h4'>
                            {prompt.Text}
                        </Typography>
                    </Button>
                </Grid>
            );
        }

        return (
            <Card>
                <CardContent>
                    {challengeImage}
                    {challengeText}
                    </CardContent>
                        {drop}
                    <CardContent>
                    <Grid 
                        container 
                        direction='column'
                        alignItems='center'
                        rowSpacing={2} 
                        columnSpacing={2}
                        >
                        {q.Prompts.map(p => promptToButton(q, p))}
                    </Grid>
                    <TimerControl
                        totalTime={TimerSettings.TotalTime}
                        incrementTime={TimerSettings.IncrementTime}
                        onTimeout={this.props.onTimeout} />
                </CardContent>
            </Card>
        )
    }
}