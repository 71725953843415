import { PaletteColor, createTheme } from '@mui/material';
import { blue, orange, green, purple, red, grey } from '@mui/material/colors';

const defaultTheme = createTheme({});

/* Color Module Augmentation */
declare module '@mui/material/styles' {
  interface Palette {
    promptA: Palette['primary'];
    promptB: Palette['primary'];
    promptC: Palette['primary'];
    promptD: Palette['primary'];
    promptE: Palette['primary'];
  }
  interface PaletteOptions {
    promptA: PaletteOptions['primary'];
    promptB: PaletteOptions['primary'];
    promptC: PaletteOptions['primary'];
    promptD: PaletteOptions['primary'];
    promptE: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    promptA: true,
    promptB: true,
    promptC: true,
    promptD: true,
    promptE: true,
  }
}

const colors = ["promptA", "promptB", "promptC", "promptD", "promptE"];
    function createPaletteColor(mainColor: string, name: string): PaletteColor {
      return defaultTheme.palette.augmentColor({
          color: {
              main: mainColor
          },
          name: name
      });
    }

export function DarkTheme() {
  return createTheme({
    palette: {
      mode: 'dark',
      promptA: createPaletteColor(grey[900], "promptA"),
      promptB: createPaletteColor(grey[900], "promptB"),
      promptC: createPaletteColor(grey[900], "promptC"),
      promptD: createPaletteColor(grey[900], "promptD"),
      promptE: createPaletteColor(grey[900], "promptE")
    }
  })
}

export function AmericaTheme() {
  return createTheme(defaultTheme, {
    palette: {
      promptA: createPaletteColor(red["A400"], "promptA"),
      promptB: createPaletteColor(grey[50], "promptB"),
      promptC: createPaletteColor(blue[900], "promptC"),
      promptD: createPaletteColor(red[600], "promptD"),
      promptE: createPaletteColor(blue["A700"], "promptE"),
    }
  });
}

export function RedTheme() {
  return createTheme(defaultTheme, {
    palette: {
      promptA: createPaletteColor(red[300], "promptA"),
      promptB: createPaletteColor(red[400], "promptB"),
      promptC: createPaletteColor(red[500], "promptC"),
      promptD: createPaletteColor(red[600], "promptD"),
      promptE: createPaletteColor(red[700], "promptE"),
    }
  });
}

export function GreenTheme() {
  return createTheme(defaultTheme, {
    palette: {
      promptA: createPaletteColor(green[300], "promptA"),
      promptB: createPaletteColor(green[400], "promptB"),
      promptC: createPaletteColor(green[500], "promptC"),
      promptD: createPaletteColor(green[600], "promptD"),
      promptE: createPaletteColor(green[700], "promptE"),
    }
  });
}

export function BlueTheme() {
  return createTheme(defaultTheme, {
    palette: {
      promptA: createPaletteColor(blue[300], "promptA"),
      promptB: createPaletteColor(blue[400], "promptB"),
      promptC: createPaletteColor(blue[500], "promptC"),
      promptD: createPaletteColor(blue[600], "promptD"),
      promptE: createPaletteColor(blue[700], "promptE"),
    }
  });
}

export function ComplexTheme() {
    return createTheme(defaultTheme, {
      palette: {
        promptA: createPaletteColor(blue[600], "promptA"),
        promptB: createPaletteColor(orange[600], "promptB"),
        promptC: createPaletteColor(purple[600], "promptC"),
        promptD: createPaletteColor(green[600], "promptD"),
        promptE: createPaletteColor(blue[800], "promptE"),
      }
    })
}