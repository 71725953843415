import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { DropProvider } from '../data/DropProvider';
import { Drop } from './Drop';
import UserSettings from '../data/UserSettings';

type state = {}
type props = {
    OnAdvance: () => void,
    UserSettings: UserSettings,
    Score: number,
    MaxScore: number,
    dropProvider: DropProvider
}

class ScoreControl extends React.Component<props, state> {
    constructor(props: props | Readonly<props>) {
        super(props);

    }

    GetContent(drop: DropProvider, num: number): [drop: string, text: string] {
        if (num > 0.95) {
            return [drop.ILoveYou(), "Great Job!"]
        } else if (num > 0.8) {
            return [drop.Sniffing(), "Good work."]
        } else if (num > 0.6) {
            return [drop.ImNotHereToPlayStupidGamesWithMorons(), "Better luck next time!"]
        } else if (num > 0.4) {
            return [drop.ItIsDisgusting(), "Ooof, ugh."]
        } else {
            return [drop.YoureTerrible(), "Gross!"]
        }
    }

    render() {
        let scorePct = this.props.MaxScore === 0
            ? 0.0
            : this.props.Score/this.props.MaxScore;
        let [drop, text] = this.GetContent(this.props.dropProvider, scorePct);

        return (
            <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={12}>
                    <Typography variant='h4'>
                        {text}
                    </Typography>
                    <Drop 
                      url={drop} 
                      volume={this.props.UserSettings.Volume}
                      autoPlay={true} />
                    <Typography variant='h3'>
                        Score: {this.props.Score}/{this.props.MaxScore}
                    </Typography>
                    <Typography variant='h4'>
                        Thank you so much for playing!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant='contained'
                        onClick={(e) => this.props.OnAdvance()}>
                        To Library
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default ScoreControl;