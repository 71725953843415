import React from 'react';
import Grid from '@mui/material/Grid'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { BoardCover } from '../data/Board';

type Props = {
    Library: Array<BoardCover>,
    OnLibraryOpen: (board: BoardCover) => void
}

type State = {}

export default class LibraryControl extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Grid container justifyContent="center" spacing={3}>
                {this.props.Library.map(q => {
                    return (
                    <Grid xs={6} md={4} item key={q.BoardKey}>
                        <Card>
                            <CardMedia
                                component="img"
                                image={q.ImageSrc!}
                                width="100%"
                                height="auto"
                                />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {q.Title}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button 
                                    size="small" 
                                    onClick={(e) => this.props.OnLibraryOpen(q)}>
                                    Play
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    );
                })}
            </Grid>
        );
    }
}