import React from 'react';
import { withRouter, RoutedProps } from './withRouter';
import BoardData from './data/BoardData'
import { Board } from './data/Board';
import Layout from './layout/Layout';
import UserSettings from './data/UserSettings';
import { DarkTheme } from './Styles/StyleHelper';
import { Grid } from '@mui/material';
import QuestionControl from './control/QuestionControl';
import { GetQuestionResult, Prompt, Question, Solution } from './data/Question';
import SolutionControl from './control/SolutionControl';
import ScoreControl from './control/Score';
import { DropProvider } from './data/DropProvider';
import CategoryLabelControl from './control/CategoryLabelControl';
import { DisplayElement } from './data/Models';

const provider: DropProvider = new DropProvider("/drops/");
const Timeout: Solution = [false, new DisplayElement("Timeout!", null, provider.Toilets())]

type Props = {
    boardkey: string
}

type State = {
    UserSettings: UserSettings
    PlayDrop: boolean,
    BoardProvider: BoardData,
    Board: Board | null
    Index: [number, number],
    Solution: Solution | null,
    Score: number,
    ShowChapterLabel: boolean,
    ShowScoreScreen: boolean
}

class BoardPage extends React.Component<RoutedProps<Props>, State> {
    constructor(props: RoutedProps<Props> | Readonly<RoutedProps<Props>>) {
        super(props);
        this.navigateHome = this.navigateHome.bind(this);
        this.onSettingsChanged = this.onSettingsChanged.bind(this);
        this.getCurrentQuestion = this.getCurrentQuestion.bind(this);
        this.onSolutionBack = this.onSolutionBack.bind(this);
        this.onSolutionAdvance = this.onSolutionAdvance.bind(this);
        this.onPromptSelection = this.onPromptSelection.bind(this);
        this.getContent = this.getContent.bind(this);
        this.onAdvanceOffCategory = this.onAdvanceOffCategory.bind(this);
        this.onTimeout = this.onTimeout.bind(this);

        let settingsData = this.props.storage.getItem("settings");
        let us: UserSettings = settingsData === null
          ? {Volume: 1.0, AutoPlay: true, Theme: null}
          : JSON.parse(settingsData) as UserSettings;

        let boardData = new BoardData("/boards/");

        this.state = {
            UserSettings: us,
            BoardProvider: boardData,
            Board: null,
            Index: [0, 0],
            PlayDrop: true,
            Solution: null,
            Score: 0,
            ShowChapterLabel: false,
            ShowScoreScreen: false
        }
    }

    navigateHome() {
        this.props.navigate("/");
    }

    onSettingsChanged(us: UserSettings) {
        this.props.storage.setItem("settings", JSON.stringify(us));
        this.setState({UserSettings: us});
    }
    
    componentDidMount(): void {
        this.state.BoardProvider.GetBoard(this.props.params.boardkey)
            .then(b => this.setState({
                Board: b,
                ShowChapterLabel: true
            }));
    }

    getCurrentQuestion(): Question | null {
        if (this.state.Board === null) {
            return null;
        }

        let [categoryIndex, questionIndex] = this.state.Index;
        return this.state.Board!.Categories[categoryIndex].Questions[questionIndex];
    }

    onPromptSelection(q: Question, prompt: Prompt): void {
        let result = GetQuestionResult(q, prompt);
        this.setState({
            Solution: [result.IsCorrect, result.Display] as Solution,
            PlayDrop: true
        });
    }

    onSolutionBack(): void {
        this.setState({
            Solution: null,
            PlayDrop: false
        });
    }

    onSolutionAdvance(): void {
        // Increment Score
        let currentScore = this.state.Score;
        if (this.state.Solution![0]) {
            currentScore++;
        }

        let board = this.state.Board!;
        let [catIndex, questionIndex] = this.state.Index;
        let currCat = board.Categories[catIndex];

        if (questionIndex + 1 < currCat.Questions.length) {
            // Next Question
            this.setState({
                Solution: null,
                Index: [catIndex, questionIndex + 1],
                Score: currentScore
            })

            return;
        }

        if (catIndex + 1 < board.Categories.length) {
            // Next Category
            this.setState({
                Solution: null,
                Index: [catIndex + 1, 0],
                Score: currentScore,
                ShowChapterLabel: true
            })

            return;
        }

        // End of the puzzle
        this.setState({
            Solution: null,
            ShowScoreScreen: true
        })
    }

    onAdvanceOffCategory(): void {
        this.setState({
            ShowChapterLabel: false
        });
    }

    onTimeout(): void {
        this.setState({
            Solution: Timeout,
            PlayDrop: true
        });
    }

    getContent(): JSX.Element {
        if (this.state.ShowScoreScreen) {
            let cats = this.state.Board!.Categories;
            let maxScore = cats.flatMap(c => c.Questions).length;
            return (
                <ScoreControl
                  OnAdvance={this.navigateHome}
                  UserSettings={this.state.UserSettings}
                  Score={this.state.Score}
                  MaxScore={maxScore}
                  dropProvider={new DropProvider("/drops/")}
                  />
            );
        }

        if (this.state.ShowChapterLabel) {
            let category = this.state.Board!.Categories[this.state.Index[0]];
            let chapterNumber = this.state.Index[0] + 1;
            return (
                <CategoryLabelControl
                    de={category.Title}
                    us={this.state.UserSettings}
                    chapterNum={chapterNumber}
                    OnNext={this.onAdvanceOffCategory} />
            )
        }

        if (this.state.Solution !== null) {
            return (
                <SolutionControl
                    Solution={this.state.Solution!}
                    UserSettings={this.state.UserSettings}
                    OnAdvance={this.onSolutionAdvance}
                    OnBack={this.onSolutionBack} />
            );
        }

        let question = this.getCurrentQuestion();

        let content = question === null
            ? <label>Loading...</label>
            : <QuestionControl
                userSettings={this.state.UserSettings}
                question={question!}
                autoPlayDrop={this.state.PlayDrop}
                onSelected={this.onPromptSelection}
                onTimeout={this.onTimeout} />

        return content;
    }

    render() {
        let content = this.getContent();

        return (
            <Layout
              UserSettings={this.state.UserSettings}
              theme={DarkTheme()}
              onNavigateHome={this.navigateHome}
              onSettingsChanged={this.onSettingsChanged}>
                <Grid container justifyContent="center">
                    <Grid item style={{width: "350px", maxWidth: "400px"}}>
                        {content}
                    </Grid>
                </Grid>
            </Layout>
        )
    }
}

export default withRouter(BoardPage);