export enum BaseImageEnum {
    Pass,
    Fail,
    Question
}

export class BaseImage { 
    constructor(i: BaseImageEnum) {
        this.i = i;
    }
    i: BaseImageEnum;
}

export class DisplayElement {
    constructor(text: string | null, image: BaseImage | string | null, soundSrc: string | null) {
        this.Text = text;
        this.Image = image;
        this.SoundSrc = soundSrc;
    }
    Text: string | null
    Image: BaseImage | string | null
    SoundSrc: string | null
}

