import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { BaseImage, DisplayElement } from '../data/Models';
import UserSettings from '../data/UserSettings';
import { Drop } from './Drop';

type Props = {
    chapterNum: number,
    de: DisplayElement,
    us: UserSettings,
    OnNext: () => void
}

type State = { }

let GetImageElement = (de: DisplayElement) => {
    if (de.Image === null) {
        return null;
    }

    let image: string | BaseImage = de.Image;
    if (image instanceof BaseImage) {
        return null;
    } else {
        return (
            <CardMedia
                component="img"
                image={(image as string)}
                width="100%"
                height="auto"
                sx={{maxHeight: "50"}}
                />
        )
    }
}

export default class CategoryLabelControl extends React.Component<Props, State> {

    render() {
        let de = this.props.de;
        let us = this.props.us;

        let chapterHeaderText = "Chapter " + this.props.chapterNum.toString();
        let image = GetImageElement(de);
        let drop = de.SoundSrc === null 
            ? null 
            : <Drop 
                url={de.SoundSrc} 
                volume={us.Volume} 
                autoPlay={us.AutoPlay} />

        return (
            <Card>
                <CardHeader 
                    title={chapterHeaderText} />
                <CardContent>
                    {image}
                    <Typography 
                        variant='h2'>
                        {de.Text}
                    </Typography>
                    {drop}
                </CardContent>
                <CardActions>
                    <Button onClick={this.props.OnNext}>Next</Button>
                </CardActions>
            </Card>
        )
    }
}