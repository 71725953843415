import React from 'react';
import './App.css';
import LibraryControl from './control/LibraryControl';
import { RoutedProps, withRouter } from './withRouter';
import { DarkTheme } from './Styles/StyleHelper';
import Layout from './layout/Layout';
import UserSettings from './data/UserSettings';
import BoardData from './data/BoardData';
import { BoardCover } from './data/Board';

type State = {
  Library: Array<BoardCover>,
  UserSettings: UserSettings
}

type Props = {
}

class App extends React.Component<RoutedProps<Props>, State> {
  Provider: BoardData

  constructor(props: any) {
    super(props);

    this.OnLibraryOpen = this.OnLibraryOpen.bind(this);
    this.ToLibrary = this.ToLibrary.bind(this);
    this.OnSettingsChange = this.OnSettingsChange.bind(this);

    let settingsData = this.props.storage.getItem("settings");
    let us: UserSettings = settingsData === null
      ? {Volume: 1.0, AutoPlay: true, Theme: null}
      : JSON.parse(settingsData) as UserSettings

    this.Provider = new BoardData('/boards/');
    this.state = {
      Library: this.Provider.GetBoards(),
      UserSettings: us
    };
  }

  OnLibraryOpen(boardCover: BoardCover) {
    let relativePath = "/board/" + boardCover.BoardKey
    this.props.navigate(relativePath);
  }

  ToLibrary() {
    this.props.navigate("/");
  }

  OnSettingsChange(us: UserSettings) {
    this.setState({UserSettings: us});
  }

  render(): React.ReactNode {
    return (
    <Layout 
      onNavigateHome={this.ToLibrary} 
      onSettingsChanged={this.OnSettingsChange}
      UserSettings={this.state.UserSettings}
      theme={DarkTheme()}>
      <LibraryControl 
        Library={this.state.Library}
        OnLibraryOpen={this.OnLibraryOpen}
        />
    </Layout>
    )
  }
}

export default withRouter(App)