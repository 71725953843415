import React from 'react'
import { AppBar, Box, CssBaseline, Dialog, Grid, Theme, ThemeProvider, Toolbar, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import UserSettings from '../data/UserSettings';
import SettingsModal from '../control/SettingsModal';

type State = {
    open: boolean
}

type Props = {
    UserSettings: UserSettings,
    theme: Theme,
    onNavigateHome: () => void,
    onSettingsChanged: (us: UserSettings) => void
}

class Layout extends React.Component<React.PropsWithChildren<Props>, State> {
    constructor(props: any) {
        super(props);

        this.openSettings = this.openSettings.bind(this);
        this.closeSettings = this.closeSettings.bind(this);
        this.volumeChanged = this.volumeChanged.bind(this);
        this.autoPlayChanged = this.autoPlayChanged.bind(this);

        this.state = {
            open: false
        };
    }

    openSettings() {
        this.setState({open: true});
    }

    closeSettings() {
        this.setState({open: false});
    }

    volumeChanged(newVolume: number) {
        this.props.onSettingsChanged({
            Volume: newVolume,
            AutoPlay: this.props.UserSettings.AutoPlay,
            Theme: this.props.UserSettings.Theme
        });
    }

    autoPlayChanged(isChecked: boolean) {
        let se = this.props.UserSettings;
        this.props.onSettingsChanged({
            Volume: se.Volume,
            AutoPlay: isChecked,
            Theme: se.Theme
        })
    }

    render() {
        return (
            <ThemeProvider theme={this.props.theme}> 
                <CssBaseline />
                <Box>
                    <AppBar position="fixed" color='primary'>
                        <Toolbar variant='dense'>
                            <Typography 
                                onClick={(e) => this.props.onNavigateHome()} 
                                noWrap
                                variant="h5" 
                                component="a"
                                sx={{ display: 'block', flexGrow: 1 }}>
                                Trivia App
                            </Typography>
                            <SettingsIcon onClick={(e) => this.openSettings()} />
                            <Dialog
                                open={this.state.open}
                                onClose={this.closeSettings}>
                              <SettingsModal 
                                UserSettings={this.props.UserSettings} 
                                open={this.state.open} 
                                OnClose={this.closeSettings} 
                                OnVolumeChanged={this.volumeChanged} 
                                OnAutoPlayChanged={this.autoPlayChanged}/>
                            </Dialog>

                        </Toolbar>
                    </AppBar>
                    <Box m={3} sx={{paddingTop: "48px"}}>
                        <Grid>
                            {this.props.children}
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>

        );
    }
}

export default Layout