import { DisplayElement } from "./Models";

export class Prompt {
    constructor(code: PromptCodeEnum, text: string) {
        this.Code = code;
        this.Text = text;
    }

    Code: PromptCodeEnum
    Text: string
}

export class QuestionResult {
    constructor(codes: Array<PromptCodeEnum>, isCorrect: Boolean, display: DisplayElement) {
        this.Codes = codes;
        this.IsCorrect = isCorrect;
        this.Display = display;
    }

    Codes: Array<PromptCodeEnum>
    IsCorrect: Boolean
    Display: DisplayElement
}

export class Question {
    constructor(challenge: DisplayElement, prompts: Array<Prompt>, results:  Array<QuestionResult>) {
        this.Challenge = challenge;
        this.Prompts = prompts;
        this.Results = results;
    }

    public Challenge: DisplayElement
    public Prompts: Array<Prompt>
    public Results: Array<QuestionResult>
}

export function GetQuestionResult(q: Question, prompt: Prompt): QuestionResult {
    let result = q.Results.filter(qr => qr.Codes.filter(c => c === prompt.Code).length > 0)
    if (result.length !== 1) {
        Error("Expecting one result");
    }
    return result[0];
}

export type Solution = [boolean, DisplayElement]

export enum PromptCodeEnum {
    A,
    B,
    C,
    D,
    E,
    F,
    G,
    H,
    I,
    J,
    K
}