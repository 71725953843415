import { Board, BoardCover } from "./Board";

export default class BoardData {
    constructor(path: string) {
        this.Path = path;
    }

    Path: string

    GetBoards(): Array<BoardCover> {
        return [
            { BoardKey: "rm-brown", Title: "RM Brown", ImageSrc: "/img/rmbrown.jpg" },
            { BoardKey: "mc-beginner1", Title: "Minecraft Basics", ImageSrc: "/img/mc_cover.jpg"}
        ]
    }

    GetBoard(boardKey: string): Promise<Board> {
        return fetch(this.Path + boardKey + ".json")
            .then(response => response.json())
            .then(boardObj => boardObj as Board)
    }
}