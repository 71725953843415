import { Stack } from '@mui/material';
import React from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

type State = {
    CurrentTime: number,
    BarCount: number
}

type Props = {
    totalTime: number,
    incrementTime: number,
    onTimeout: () => void
}

const centerArrow = <HourglassEmptyIcon key={"C"}/>

export default class TimerControl extends React.Component<Props, State> {
    timer: number | null
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.intervalElapsed = this.intervalElapsed.bind(this);

        this.timer = null;
        this.state = {
            CurrentTime: 0,
            BarCount: props.totalTime / props.incrementTime
        }
    }

    intervalElapsed(): void {
        let rawTime = this.state.CurrentTime + this.props.incrementTime;
        let setTime = Math.min(rawTime, this.props.totalTime);
        if (setTime === this.props.totalTime) {
            this.props.onTimeout();
            console.log("-- Timeout -- ");
        } else {
            let barCount = Math.max(this.state.BarCount - 1, 0);
            console.log("Countdown: " + barCount.toString());
            this.setState({
                CurrentTime: setTime, 
                BarCount: barCount
            });
        }
    }

    componentDidMount(): void {
        this.timer = setInterval(() => {
            this.intervalElapsed();
        }, this.props.incrementTime, null)
    }

    componentWillUnmount(): void {
        if (this.timer !== null) {
            clearInterval(this.timer)
        }
    }

    getElements(subBarCount: number): JSX.Element[] {
        if (subBarCount < 0) {
            return new Array<JSX.Element>();
        }

        if (subBarCount === 0) {
            return [centerArrow]
        }

        let elements: JSX.Element[] = new Array<JSX.Element>(subBarCount * 2 + 1);
        for (let i = 0; i < subBarCount; i++) {
            elements[i] = <ArrowForwardRoundedIcon key={"f" + i.toString()} />
        }

        elements[subBarCount] = centerArrow;

        for (let i = 0; i < subBarCount; i++) {
            elements[i + subBarCount + 1] = <ArrowBackRoundedIcon key={"b" + i.toString()} />
        }

        return elements;
    }

    render() {
        if (this.state.BarCount === 0) {
            return null;
        }

        let subBarCount = this.state.BarCount - 1;

        return (
            <Stack p={2} direction="row" spacing={1} justifyContent='center'>
                {this.getElements(subBarCount)}
            </Stack>
        )
    }
}