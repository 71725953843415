import React from 'react';
import { green, red } from '@mui/material/colors';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { BaseImageEnum, DisplayElement, BaseImage } from '../data/Models';
import { Prompt, Question, Solution } from '../data/Question';
import { Quiz } from '../data/Quiz';
import SolutionControl from './SolutionControl';
import { DarkTheme } from '../Styles/StyleHelper';
import { Drop } from './Drop';
import { withRouter, RoutedProps } from '../withRouter';
import Layout from '../layout/Layout';
import { JSX } from 'react/jsx-runtime';
import ScoreControl from './Score';
import { DropProvider } from '../data/DropProvider';
import UserSettings from '../data/UserSettings';

type State = {
    quiz: Quiz
    questionIndex: number,
    solution: Solution | null,
    score: number,
    autoPlayDrop: boolean,
    settings: UserSettings
}

type Props = {
    quizid: string
}

let GetImageElement = (de: DisplayElement) => {
    if (de.Image === null) {
        return null;
    }

    let image: string | BaseImage = de.Image;
    if (image instanceof BaseImage) {
        let iv = (image as BaseImage).i;

        if (iv === BaseImageEnum.Pass) {
            return (<DoneOutlineRoundedIcon sx={{fontSize: 40, color: green[400]}} />);
        } 
        if (iv === BaseImageEnum.Fail) {
            return (<CloseRoundedIcon sx={{fontSize: 40, color: red[900]}} />);
        }

        return null;
    } else {
        return (
            <CardMedia
            component="img"
            image={(image as string)}
            width="100%"
            height="auto"
            sx={{maxHeight: "50"}}
            />
        )
    }
}

class QuizPage extends React.Component<RoutedProps<Props>, State> {
    constructor(props: RoutedProps<Props> | Readonly<RoutedProps<Props>>) {
        super(props);
        this.ScoreHelper = this.ScoreHelper.bind(this);
        this.OnAdvance = this.OnAdvance.bind(this);
        this.OnBack = this.OnBack.bind(this);
        this.ToLibrary = this.ToLibrary.bind(this);
        this.OnSettingsChanged = this.OnSettingsChanged.bind(this);

        const quizID = props.params.quizid;

        if (quizID === null) {
            console.error("No Quiz ID found in params.");
            return;
        }

        let quiz = {} as Quiz
        if (quiz === null) {
            console.error("No Quiz found in QuizProvider.");
            return;
        }

        let settingsData = props.storage.getItem("settings");
        let us: UserSettings = settingsData === null 
            ? { Volume: 1.0, AutoPlay: true, Theme: null} 
            : JSON.parse(settingsData) as UserSettings

        this.state = {
            quiz: quiz,
            questionIndex: 0,
            solution: null,
            score: 0,
            autoPlayDrop: true,
            settings: us
        };
    }

    OnAdvance() {
        let isCorrect = this.state.solution![0];
        this.setState({
            questionIndex: this.state.questionIndex + 1,
            solution: null,
            score: this.state.score + (isCorrect ? 1 : 0),
            autoPlayDrop: true
        });
    }

    OnBack() {
        let isCorrect = this.state.solution![0];
        this.setState({
            solution: null,
            score: this.state.score - (isCorrect ? 1 : 0),
            autoPlayDrop: false
        });
    }

    ScoreHelper(q: Question, p: Prompt) {
    }

    ToLibrary() {
        this.props.navigate("/");
    }

    OnSettingsChanged(us: UserSettings) {
        this.props.storage.setItem("settings", JSON.stringify(us));
        this.setState({settings: us});
    }

    render() {
        if (this.state.solution !== null) {
            return (
                <Layout 
                    onNavigateHome={this.ToLibrary} 
                    onSettingsChanged={this.OnSettingsChanged}
                    UserSettings={this.state.settings}
                    theme={DarkTheme()}>
                    <Grid container justifyContent="center">
                        <Grid item style={{width: "350px", maxWidth: "400px"}}>
                            <SolutionControl
                                Solution={this.state.solution!}
                                UserSettings={this.state.settings}
                                OnAdvance={this.OnAdvance} 
                                OnBack={this.OnBack}
                                />
                        </Grid>
                    </Grid>
                </Layout>
            );
        }
        let question = this.state.quiz.Questions[this.state.questionIndex]
        if (question === undefined || question === null) {
            let maxScore = this.state.quiz.Questions.length;
            return (
                <Layout onNavigateHome={this.ToLibrary} onSettingsChanged={this.OnSettingsChanged} UserSettings={this.state.settings} theme={DarkTheme()}>
                    <Grid container justifyContent="center">
                        <Grid item style={{width: "350px", maxWidth: "400px"}}>
                            <ScoreControl
                                OnAdvance={this.ToLibrary}
                                Score={this.state.score}
                                UserSettings={this.state.settings}
                                MaxScore={maxScore}
                                dropProvider={new DropProvider("/drops/")} />
                        </Grid>
                    </Grid>
                </Layout>
            );
        }

        let tryGetPrompt = (i: number) => i < question.Prompts.length ? question.Prompts[i] : null;
        let promptA = tryGetPrompt(0);
        let promptB = tryGetPrompt(1);
        let promptC = tryGetPrompt(2);
        let promptD = tryGetPrompt(3);
        let promptE = tryGetPrompt(4);
        let challengeText = question.Challenge.Text === null
            ? null
            : (     
                        <Typography gutterBottom variant="h5">
                            {question.Challenge.Text}
                        </Typography>
                    
              );
        let challengeImage = GetImageElement(question.Challenge);
        let drop = question.Challenge.SoundSrc === null 
            ? null 
            : <Drop 
                url={question.Challenge.SoundSrc} 
                volume={this.state.settings.Volume} 
                autoPlay={this.state.settings.AutoPlay && this.state.autoPlayDrop} />

        let withButtonGrid = (jsx: JSX.Element) => {
            return (
              <Grid item xs={12}>
                {jsx}
              </Grid>
            )
        }

        return (
            <Layout onNavigateHome={this.ToLibrary} onSettingsChanged={this.OnSettingsChanged} UserSettings={this.state.settings} theme={DarkTheme()}>
              <Grid container justifyContent="center">
                <Grid item style={{width: "350px", maxWidth: "400px"}}>
                    <Card>
                        <CardContent>
                        {challengeImage}
                        {challengeText}
                        </CardContent>
                        {drop}
                        <CardContent>
                            <Grid container rowSpacing={2} columnSpacing={2} >
                                {promptA === null 
                                    ? null 
                                    : withButtonGrid(<Button
                                        key={promptA!.Text}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => this.ScoreHelper(question, promptA!)}
                                        color="promptA"
                                        >
                                        {promptA!.Text}
                                    </Button>)
                                }
                                {promptB === null 
                                    ? null 
                                    : withButtonGrid(<Button
                                        key={promptB!.Text}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => this.ScoreHelper(question, promptB!)}
                                        color="promptB"
                                        >
                                        {promptB!.Text}
                                    </Button>)
                                }
                                {promptC === null 
                                    ? null 
                                    : withButtonGrid(<Button
                                        key={promptC!.Text}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => this.ScoreHelper(question, promptC!)}
                                        color="promptC"
                                        >
                                        {promptC!.Text}
                                    </Button>)
                                }
                                {promptD === null 
                                    ? null 
                                    : withButtonGrid(<Button
                                        key={promptD!.Text}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => this.ScoreHelper(question, promptD!)}
                                        color="promptD"
                                        >
                                        {promptD!.Text}
                                    </Button>)
                                }
                                {promptE === null 
                                    ? null 
                                    : withButtonGrid(<Button
                                        key={promptE!.Text}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => this.ScoreHelper(question, promptE!)}
                                        color="promptE"
                                        >
                                        {promptE!.Text}
                                    </Button>)
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
              </Grid>
            </Layout>
        )
    }
}

export default withRouter(QuizPage);