import React from 'react'
import { DisplayElement, BaseImageEnum, BaseImage } from '../data/Models'
import { Solution } from '../data/Question';
import { Button, Card, CardActions, CardMedia, CardContent, Typography } from '@mui/material';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { green, red } from '@mui/material/colors';
import { Drop } from './Drop';
import UserSettings from '../data/UserSettings';

type State = {}
type Props = {
    OnAdvance: () => void,
    OnBack: () => void | null,
    Solution: Solution,
    UserSettings: UserSettings
}

let easyTrueFalseImage = (isCorrect: boolean) => {
    return isCorrect
        ? (<DoneOutlineRoundedIcon sx={{fontSize: 50, justifyContent: 'center', color: green[400]}} />)
        : (<CloseRoundedIcon sx={{fontSize: 50, justifyContent: 'center', color: red[900]}} />);
} 

let GetImageElement = (de: DisplayElement) => {
    if (de.Image === null) {
        return null;
    }

    let image: string | BaseImage = de.Image;
    if (image instanceof BaseImage) {
        let iv = (image as BaseImage).i;
        if (iv === BaseImageEnum.Pass || iv === BaseImageEnum.Fail) {
            return easyTrueFalseImage(iv === BaseImageEnum.Pass);
        } 

        return null;
    } else {
        return (
            <CardMedia
            component="img"
            image={(image as string)}
            width="100%"
            height="auto"
            />
        )
    }
}

export default class SolutionControl extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
    }

    render(): React.ReactNode {
        let isCorrect = this.props.Solution[0]
        let correctIcon = easyTrueFalseImage(isCorrect);
        let correctText = isCorrect ? "Correct" : "Incorrect"
        let explanation = this.props.Solution[1] === null
            ? <label></label>
            : <label>{this.props.Solution[1].Text}</label>
        let icon = GetImageElement(this.props.Solution[1]);
        let drop = this.props.Solution[1].SoundSrc === null 
            ? null 
            : <Drop 
                url={this.props.Solution[1].SoundSrc} 
                volume={this.props.UserSettings.Volume} 
                autoPlay={this.props.UserSettings.AutoPlay} />
        return (
            <Card>
                <CardContent>
                    {correctIcon}
                    <Typography variant='h3' component='span'>
                        {correctText}
                    </Typography>
                </CardContent>
                <CardContent>
                    {icon}
                    {explanation}
                </CardContent>
                {drop}
                <CardActions>
                    {
                        this.props.OnBack === null 
                            ? null 
                            : <Button 
                            size="small" 
                            onClick={(e) => this.props!.OnBack()}
                            >
                            Previous
                        </Button>
                    }
                    
                    <Button 
                        size="small" 
                        onClick={(e) => this.props.OnAdvance()}
                        >
                        Next
                    </Button>
                </CardActions>
            </Card>
        );
    }
}