import React, { ChangeEvent } from 'react';
import UserSettings from '../data/UserSettings';
import { Box, Checkbox, Dialog, DialogContent, FormControlLabel, Slider, Stack } from '@mui/material';
import { VolumeDown, VolumeUp } from '@mui/icons-material';

type Props = {
    UserSettings: UserSettings,
    open: boolean,
    OnClose: () => void,
    OnVolumeChanged: (num: number) => void
    OnAutoPlayChanged: (isChecked: boolean) => void
}

type State = {}

const style = {
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: 200,
    Height: 50,
    transform: 'translate(-50%, -50%)',
}

class SettingsModal extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.VolumeChange = this.VolumeChange.bind(this);
        this.AutoPlayChanged = this.AutoPlayChanged.bind(this);
    }

    VolumeChange(event: Event, newValue: number | number[]) {
        let currVolume = this.props.UserSettings.Volume;
        let newVolume = newValue as number;
        if (currVolume !== newVolume) {
            this.props.OnVolumeChanged(newVolume);
        }
    }

    AutoPlayChanged(event: ChangeEvent<HTMLInputElement>, isChecked: boolean) {
        let currValue = this.props.UserSettings.AutoPlay;
        if (isChecked !== currValue) {
            this.props.OnAutoPlayChanged(isChecked);
        }
    }

    render() {
        return (
              <DialogContent>
                <Stack direction='row' >
                    <VolumeDown />
                    <Slider
                        sx={{width: 125, mx: 1}}
                        value={this.props.UserSettings.Volume}
                        onChange={this.VolumeChange}
                        min={0}
                        max={1.0}
                        step={0.05} />
                    <VolumeUp />
                </Stack>
                <FormControlLabel
                  label="Auto-Play"
                  control={
                    <Checkbox 
                      checked={this.props.UserSettings.AutoPlay} 
                      onChange={this.AutoPlayChanged} />
                  } />
              </DialogContent>
        )
    }
}

export default SettingsModal;