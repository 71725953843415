export class DropProvider {
    constructor(basePath: string) {
        this.BasePath = basePath;
    }

    BasePath: string;

    ACrystalFlute(): string {
        return this.BasePath + "a-crystal-flute.mp3";
    }

    Banjo(): string {
        return this.BasePath + "banjo.mp3";
    }

    BeatAKidAtWalmart(): string {
        return this.BasePath + "beat-a-kid-at-walmart.mp3";
    }

    BootyHoleWaxedDown(): string {
        return this.BasePath + "booty-hole-waxed-down.mp3";
    }

    CallACrackHead(): string {
        return this.BasePath + "call-a-crackhead.mp3";
    }

    ELooMah(): string {
        return this.BasePath + "yi-long-ma.mp3";
    }

    EveryonesScared(): string {
        return  this.BasePath + "everyones-scared.mp3"
    }

    EverySinglePersonOnThisPlanetIsGay(): string {
        return this.BasePath + "every-single-person-on-this-planet-is-gay.mp3";
    }

    GenerallySpeakingChinesePeopleLookChinese(): string {
        return this.BasePath + "generally-speaking-chinese-people-look-chinese.mp3";
    }

    Gunshot(): string {
        return this.BasePath + "gunshot.mp3";
    }

    Hooaooa(): string {
        return this.BasePath + "hooaooa.mp3";
    }

    IAmHavingTroubleDoingAShow(): string {
        return this.BasePath + "im-having-trouble-doing-a-show.mp3"
    }

    ImNotHereToPlayStupidGamesWithMorons(): string {
        return this.BasePath + "im-not-here-to-play-stupid-games-with-morons.mp3"
    }

    ItIsDisgusting(): string {
        return this.BasePath + "it-is-disgusting.mp3";
    }

    ILoveYou(): string {
        return this.BasePath + "i-love-you.mp3";
    }

    Money(): string {
        return this.BasePath + "money.mp3";
    }

    PoliceSiren(): string {
        return this.BasePath + "police-siren.mp3";
    }

    Sniffing(): string {
        return this.BasePath + "sniffing.mp3";
    }

    Splat(): string {
        return this.BasePath + "splat.mp3";
    }

    TakeTheTowersDownNow(): string {
        return this.BasePath + "take-the-towers-down-now.mp3";
    }

    Toilets(): string {
        return this.BasePath + "toilets.mp3";
    }

    UberCars(): string {
        return this.BasePath + "uber-cars.mp3";
    }

    ViolentCrime(): string {
        return this.BasePath + "violent-crime.mp3"
    }

    YokoOno(): string {
        return this.BasePath + "yoko-ono.mp3";
    }

    YoureTerrible(): string {
        return this.BasePath + "youre-terrible.mp3";
    }

    YoureWelcome(): string {
        return this.BasePath + "youre-welcome.mp3";
    }

    WhatAPervert(): string {
        return this.BasePath + "what-a-pervert.mp3";
    }
};