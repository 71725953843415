import { Button } from '@mui/material';
import React from 'react';
import { PlayCircle, PauseCircle } from '@mui/icons-material';

type State = {
    audio: HTMLAudioElement | null,
    isPlaying: boolean
}

type Props = {
    url: string,
    volume: number,
    autoPlay: boolean
}

export class Drop extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            audio: props.url === null ? null : new Audio(props.url),
            isPlaying: false
        };

        if (this.state.audio !== null) {
            this.state.audio!.onended = (e) => this.end();
        }
    }

    end() {
        this.state.audio?.pause();
        this.setState({isPlaying: false});
    }

    componentWillUnmount(): void {
        let audio = this.state.audio;
        if (audio !== null && !audio.paused) {
            this.state.audio!.pause();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.url !== this.props.url) {
            this.setState({ audio: new Audio(this.props.url), isPlaying: false});
            if (this.state.audio !== null) {
                this.state.audio!.onended = (e) => this.end();
            }
        }
    }

    componentDidMount(): void {
        if (this.props.autoPlay && !this.state.isPlaying && this.state.audio !== null) {
            this.state.audio!.play().catch(e => console.error(e));
            this.setState({isPlaying: true})
        }
    }

    playPause() {
        if (this.state.isPlaying) {
            this.end();
        } else {
            this.state.audio!.volume = this.props.volume;
            this.state.audio!.play().catch(e => console.error(e));
            this.setState({isPlaying: true});
        }
    }

    render() {
        let icon = this.state.isPlaying
            ? <PauseCircle />
            : <PlayCircle />
        
        let text = this.state.isPlaying ? "Playing..." : "Play"

        return (<Button 
            variant='contained' 
            onClick={(e) => this.playPause()}
            startIcon={icon}>{text}</Button>)
    }
}